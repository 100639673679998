.flex {
  display: flex;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.create-reservation-form {
  margin: 20px;
}